import { axiosBase } from "./../services/axiosbase"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { WhiteLabel } from "interfaces/whitelabel"
import { cookies } from "utility/cookies"
export const defaultWhiteLabel = {} as WhiteLabel

export const fetchWhiteLabel = createAsyncThunk(
  "whitelabel",
  async ({ communityId, companyId }: { communityId?: string; companyId?: string }) => {
    try {
      const cookiesCommunityId = cookies.get("communityId")
      const cookiesCompanyId = cookies.get("companyId")
      if (!(communityId || cookiesCommunityId || cookiesCompanyId || companyId)) {
        cookies.remove("communityId")
        cookies.remove("companyId")
        const { data } = await axiosBase.get(`/white-label/templates/default`)
        if (data) return data
        return defaultWhiteLabel
      } else if (communityId || cookiesCommunityId) {
        const id = communityId || cookiesCommunityId
        const { data } = await axiosBase.get(`/white-label/community/${id}`)
        if (data.communityId) cookies.set("communityId", data.communityId)
        if (data?.companyId) {
          const { data: companyWhitelabel } = await axiosBase.get(`/white-label/company/${data?.companyId}`)
          data.auth = companyWhitelabel?.auth
        }
        return data
      } else if (companyId) {
        const id = companyId || cookiesCompanyId
        const { data } = await axiosBase.get(`/white-label/company/${id}`)
        if (data.companyId) cookies.set("companyId", data.companyId)
        return data
      }
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

export const whitelabelConfigSlice = createSlice({
  name: "whitelabelConfig",
  initialState: {
    whitelabel: defaultWhiteLabel,
    loading: true,
    errorMessage: undefined
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabel.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWhiteLabel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.whitelabel = payload
      })
      .addCase(fetchWhiteLabel.rejected, (state, { payload }) => {
        state.loading = false
        state.errorMessage = payload
        cookies.remove("communityId")
        cookies.remove("companyId")
      })
  }
})

export type WhiteLabelConfigState = ReturnType<typeof whitelabelConfigSlice.reducer>
export default whitelabelConfigSlice.reducer
