import { createContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import  { toast, ToastPosition } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { removeSession } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import { cookies } from "utility/cookies"
import { useAppDispatch } from "redux/hooks"

const Context = createContext(null)

export const CookieExpiracyProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const data = cookies.get("userData")
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.expires && (new Date()).toISOString() > data.expires) {
      dispatch(removeSession({}))
      dispatch(removeSessionMetamask({}))

      
      navigate("/")

      toast.error(t("Token Expired Toast"), {
        position: process.env.REACT_APP_TOAST_POSITION as ToastPosition,
        duration: Number(process.env.REACT_APP_TOAST_DURATION)
      })        
    }
  }, [data])

  return (
    <Context.Provider
    value={null}
    >
      {children}
    </Context.Provider>
  )
}