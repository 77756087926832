import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase } from "../axiosbase"
import { io } from "socket.io-client"
import axios from "axios"
import { cookies } from "../../utility/cookies"

interface FetchCommunityProductProps {
  accessToken: string
  communityId: string
}

interface FetchProductProps {
  accessToken: string
  slug: string
}

interface FetchCheckoutProps {
  accessToken: string
  body: any
}

export const getProducts = createAsyncThunk(
  "appEcommerce/getProducts",
  async ({ accessToken, communityId }: FetchCommunityProductProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = {}
    const cookiesCompanyId = cookies.get("companyId")
    const cookiesCommunityId = cookies.get("communityId")
    const community = cookiesCommunityId ? cookiesCommunityId : communityId
    if (community) {
      let { data } = await axiosBase.get(`/products/community/${community}`, config)
      if (cookiesCompanyId) {
        data = data?.filter((product) => product?.companyId === cookiesCompanyId)
      }
      return { data }
    } else {
      let { data } = await axiosBase.get(`/products/by-user`, config)
      if (cookiesCompanyId) {
        data = data?.filter((product) => product?.companyId === cookiesCompanyId)
      }
      return { data }
    }
  }
)

export const getCartItems = createAsyncThunk("appEcommerce/getCartItems", async () => {
  const response = await axiosBase.get("/apps/ecommerce/cart")
  return response.data
})

export const getProduct = createAsyncThunk(
  "appEcommerce/getProduct",
  async ({ accessToken, slug }: FetchProductProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await axiosBase.get(`/products/slug/${slug}`, config)
    return response.data
  }
)

export const checkoutProduct = createAsyncThunk(
  "appEcommerce/checkout",
  async ({ accessToken, body }: FetchCheckoutProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await axiosBase.post(`store/checkout-product`, body, config)
    return response.data
  }
)

export const fetchCheckoutTransactionRequestStatus = createAsyncThunk(
  "checkout/transactionRequestStatus",
  async ({ transactionId, accessToken }: any) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/transactions/${transactionId}/transactionRequest/status`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    return data.status
  }
)

export const watchCheckoutTransaction = (transactionId) => {
  const protocol = new URL(process.env.REACT_APP_API_URL).protocol
  const host = new URL(process.env.REACT_APP_API_URL).host
  const hostname = `${protocol}//${host}`
  const socket = io(hostname)

  socket.on("connect", () => {
    console.log("socket connected")
    console.log(`Monitorando Transaction: ${transactionId}`)
    socket.emit("lastStatusChangeFromTransaction", { transactionId })
  })

  socket.on("disconnect", () => {
    socket.removeAllListeners()
    console.log("socket disconnected")
  })

  socket.on("error", (error) => {
    socket.removeAllListeners()
    console.log("socket error", error)
  })
  return socket
}
