// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import whitelabelConfig from "./whitelabelConfig"
import auth from "../services/store"
import metamask from "../services/store/metamask"
import tokens from "../services/tokens/tokensSlice"
import collections from "../services/collections/collectionsSlice"
import communities from "../services/communities/communitiesSlice"
import communityProducts from "../services/communities/communityProductsSlice"
import ecommerce from '../services/ecommerce/ecommerceSlice'
import purchases from '../services/ecommerce/purchaseSlice'
import loading from "../services/loading/loadingSlice"

const rootReducer = { navbar, layout, whitelabelConfig, auth, metamask, tokens, collections, communities, communityProducts, ecommerce, purchases, loading }

export default rootReducer
