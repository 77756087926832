import { fetchTokens } from "./tokensActions"
import { createSlice } from "@reduxjs/toolkit"

import type { CreatedTokenWithBenefits } from "./types"

const initialTokensState = {
  tokens: [] as CreatedTokenWithBenefits[],
  tokensLoading: true,
  tokensErrorMessage: undefined
}

const tokensSlice = createSlice({
  name: "tokens",
  initialState: initialTokensState,
  reducers: {
    setTokens: (state, { payload }) => {
      state.tokens = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokens.pending, (state, _action) => {
        state.tokensLoading = true
      })
      .addCase(fetchTokens.fulfilled, (state, { payload }) => {
        state.tokensLoading = false
        state.tokens = payload
      })
      .addCase(fetchTokens.rejected, (state, { error }) => {
        state.tokensLoading = false
        state.tokensErrorMessage = error
      })
  }
})

export const { setTokens } = tokensSlice.actions

export default tokensSlice.reducer
