import React, { createContext, useContext, useState } from "react"

export interface IContext {
  showTable: "table" | "benefits" | "details"
  setShowTable(string)
  showBenefit: any
  setShowBenefit: any
  isBenefit: boolean
  setIsBenefit: (hasStoreNFTpurchased: boolean) => void
  isLogged: boolean
  setIsLogged: (hasStoreNFTpurchased: boolean) => void
  userData: UserData
  setUserData: any
}
export interface UserData {
  accessToken: string
  email: string
  id: string
  role: string
  status: string
  communities: Community[]
  secsToExpire: number
}
export interface Community {
  communityId: string
  role: string
}
export interface UserData {
  accessToken: string
  email: string
  id: string
  role: string
  status: string
  communities: Community[]
  secsToExpire: number
}
export interface Community {
  communityId: string
  role: string
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
  const [showTable, setShowTable] = useState<"table" | "benefits" | "details">("table")

  const [isBenefit, setIsBenefit] = useState(false)
  const [isLogged, setIsLogged] = useState(true)
  const [userData, setUserData] = useState({} as any)
  const [showBenefit, setShowBenefit] = useState({} as any)

  return (
    <Context.Provider
      value={{
        showTable,
        setShowTable,
        isBenefit,
        setIsBenefit,
        isLogged,
        setIsLogged,
        userData,
        setUserData,
        showBenefit,
        setShowBenefit
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
