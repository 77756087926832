// ** React Imports
import { Suspense, lazy, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { QueryClientProvider, QueryClient } from "react-query"
import { Helmet } from "react-helmet"
import "./configs/i18n"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster, ToastPosition } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { ContextProvider } from "utility/hooks/useContext"
import { fetchWhiteLabel } from "redux/whitelabelConfig"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { WagmiConfig, createClient, configureChains, chain } from "wagmi"
import { publicProvider } from "wagmi/providers/public"

import { CookieExpiracyProvider } from "contexts/CookieExpiracyContext"
import useQuery from "utility/hooks/useQuery"
import { fetchTokens } from "services/tokens/tokensActions"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

const { provider } = configureChains([chain.polygon, chain.polygonMumbai], [publicProvider()])

const client = createClient({
  autoConnect: true,
  provider
})

const ConfigProvider = () => {
  const { tokens, tokensErrorMessage } = useAppSelector((state) => state.tokens)
  const userFromGoogle = useAppSelector((state) => state.auth)
  const userFromMetamask = useAppSelector((state) => state.metamask)
  const JWT = userFromGoogle?.JWT?.accessToken ? userFromGoogle?.JWT?.accessToken : userFromMetamask.JWT?.accessToken
  const userId = userFromGoogle?.JWT?.accessToken ? userFromGoogle?.JWT?.id : userFromMetamask.JWT?.user?.id

  const [didFetchTokens, setDidFetchTokens] = useState(false)

  const dispatch = useAppDispatch()
  const query = useQuery()

  const company = query.get("company")
  const community = query.get("community")

  useEffect(() => {
    dispatch(fetchWhiteLabel({ communityId: community, companyId: company }))
    if (tokens?.length === 0 && !didFetchTokens && JWT && userId) {
      dispatch(fetchTokens({ accessToken: JWT, userId }))
      setDidFetchTokens(true)
    }
  }, [dispatch])
  return null
}

const TitleProvider = () => {
  const { whitelabel, loading } = useAppSelector((state) => state.whitelabelConfig)
  const siteTitle = whitelabel?.layout?.userSpace?.title
  const siteFavicon = whitelabel?.layout?.userSpace?.favicon
  return (
    <>
      {!loading && (
        <Helmet>
          <link rel="shortcut icon" href={siteFavicon} />
          <title>{siteTitle}</title>
        </Helmet>
      )}
    </>
  )
}

const queryClient = new QueryClient()

root.render(
  <BrowserRouter>
    <WagmiConfig client={client}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <CookieExpiracyProvider>
            <ConfigProvider />
            <TitleProvider />
            <ContextProvider>
              <Suspense fallback={<Spinner />}>
                <ThemeContext>
                  <LazyApp />
                  <Toaster
                    position={themeConfig.layout.toastPosition as ToastPosition}
                    toastOptions={{ className: "react-hot-toast" }}
                  />
                </ThemeContext>
              </Suspense>
            </ContextProvider>
          </CookieExpiracyProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiConfig>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
